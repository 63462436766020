import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdSettings } from 'react-icons/md';
import { BiLogOut } from 'react-icons/bi';
import Spinner from '../../components/Spinner';
import api from '../../api';
import { setPageTitle } from '../../utils/PageTitle';
import { showToast } from '../../components/Toast';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import { TbRefresh } from 'react-icons/tb';
import global from '../../data/global.json';

export default function Profile() {
  setPageTitle('Profile');
  const [user, setUser] = useState(undefined);
  const [disabled, setDisabled] = useState(true);
  const [orders, setOrders] = useState([]);
  const orderStatus = {
    Pending: 'warning',
    Completed: 'success',
    Failed: 'danger',
    Fulfilled: 'primary',
  };

  useEffect(() => {
    const getOrders = () => {
      api
        .get('/users/@me/orders')
        .then((response) => {
          setOrders(response.data.reverse());
        })
        .catch((error) => {
          showToast('Something Went Wrong!');
        });
    };

    getOrders();
  }, []);

  useState(() => {
    const getUser = () => {
      api
        .get('/users/@me')
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            window.location = '/sign-in';
          }
        });
    };
    getUser();
  }, []);

  const handleDisabled = (e) => {
    if (disabled) {
      e.target.innerText = 'Cancel Edit';
    } else {
      e.target.innerText = 'Edit Profile';
    }

    setDisabled(!disabled);
  };

  const handleSubmit = (e) => {};

  if (user === undefined) {
    return <Spinner />;
  }
  return (
    <>
      <div className="container">
        <div className="container-fluid mb-4">
          {user.role === 'Admin' ? (
            <Link to="/admin/manage" className="btn btn-sm btn-primary me-2">
              <MdSettings /> Manage
            </Link>
          ) : (
            ''
          )}

          <Link to="/logout" className="btn btn-sm btn-danger me-2">
            <BiLogOut /> Logout
          </Link>
        </div>
        <div className="container-fluid mb-5">
          <h2>User Profile</h2>
          <p>
            <small className="text-muted">
              Welcome to your profile settings page! Here, you have the power to
              update and manage your personal information. Update your name,
              phone number, and email address to ensure that your details are
              always up to date. Your information is important for seamless
              communication. Additionally, this is where you can find a
              comprehensive list of all the projects you have purchased.
            </small>
          </p>

          {orders.length !== 0 ? (
            <>
              <div className="mb-5">
                <h4>My Orders</h4>
                <div className="list-group list-group-flush">
                  {orders.map((order, index) => {
                    return (
                      <div
                        className="list-group-item list-group-item-action d-flex justify-content-between py-3"
                        key={index}
                      >
                        <div className="d-flex">
                          <div className="me-2">
                            <BiSolidPurchaseTag
                              className="text-primary"
                              style={{ marginTop: '-3px' }}
                            />
                          </div>
                          <div className="order-details">
                            <Link to={`/projects/${order.project.id}`}>
                              {order.project.title}
                            </Link>
                            <p>
                              <span className="badge bg-primary me-2">
                                {global.currency.ngn}
                                {order.project.price.toLocaleString()}
                              </span>

                              <span
                                className={`badge bg-${
                                  orderStatus[order.status]
                                } me-2`}
                              >
                                {order.status}
                              </span>
                              <span className={`badge bg-info me-2`}>
                                {order.project.department.name}
                              </span>
                              <span className={`badge bg-black me-2`}>
                                {new Date(order.created_at).toLocaleDateString(
                                  undefined,
                                  {
                                    year: 'numeric',
                                    month: 'short',
                                    day: 'numeric',
                                  }
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        {order.status === 'Pending' ? (
                          <Link className="btn" to={order.auth_url}>
                            <TbRefresh
                              className="text-primary px-auto"
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              data-order-id={order.id}
                              data-order-ref={order.reference}
                              title="Retry Payment"
                            />
                          </Link>
                        ) : (
                          ''
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : (
            ''
          )}

          <div className="mb-5">
            <h4>Update My Profile</h4>

            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                Firstname
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="basic-addon1"
                value={user.firstname}
                name="firstname"
                disabled={disabled}
              />
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                Lastname
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="basic-addon1"
                value={user.lastname}
                name="lastname"
                disabled={disabled}
              />
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                email
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="basic-addon1"
                value={user.email}
                name="email"
                disabled={disabled}
              />
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                Phone
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="basic-addon1"
                value={user.phone}
                name="phone"
                disabled={disabled}
              />
            </div>

            <div className="input-group mb-3">
              <span className="input-group-text" id="basic-addon1">
                Address
              </span>
              <input
                type="text"
                className="form-control"
                aria-describedby="basic-addon1"
                value={user.address}
                name="address"
                disabled={disabled}
              />
            </div>

            <button
              className="btn btn-md btn-primary me-2"
              onClick={handleDisabled}
            >
              Edit Profile
            </button>
            {disabled ? (
              ''
            ) : (
              <input
                type="submit"
                className="btn btn-md btn-secondary me-2"
                value="Update Profile"
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
