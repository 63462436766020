import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import api from '../../api';
import { showToast } from '../../components/Toast';
import { setPageTitle } from '../../utils/PageTitle';

export default function SignIn() {
  const [processing, setProcessing] = useState(false);
  setPageTitle('Sign In');
  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData(e.target);

    api
      .post('/login', formData)
      .then((response) => {
        localStorage.setItem('user', JSON.stringify(response.data));
        setProcessing(false);
        window.location = '/profile';
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response?.status === 401) {
          showToast('Invalid email/password!');
        } else if (error.response?.status === 404) {
          showToast('Invalid email/password!');
        } else {
          showToast('Something went wrong!');
        }
      });
  };

  return (
    <>
      <div className="container">
        <div className="container-fluid mb-5">
          <main className="form-signin">
            <form onSubmit={handleSubmit}>
              <h1 className="display-6 mb-3 fw-normal">Welcome back!</h1>
              <p>
                <small className="text-muted">
                  To continue your personalized experience, kindly provide your
                  login details below.
                </small>
              </p>
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  name="email"
                  required
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>
              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  name="password"
                  required
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              <div className="text-end my-3">
                <Link to="/sign-in/reset">Forgot Password</Link>
              </div>
              <button className="w-100 btn btn-lg btn-primary" type="submit">
                {processing ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>{' '}
                    Signing in...
                  </>
                ) : (
                  'Sign in'
                )}
              </button>
            </form>
            <div className="text-center text-muted mt-3">
              Don't already have an Account? <Link to="/sign-up">Sign Up!</Link>
              <br />
              <br />
              <small className="text-muted">
                Please note that if you have recently placed an order or request
                a services of a professional Writer, your account has been
                created with your [lastname] as password all in lowercase.
              </small>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
