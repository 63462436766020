import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import api from '../../../api';
import ProjectList from '../../../components/ProjectList';
import NotFound from '../../404';
import { setPageTitle } from '../../../utils/PageTitle';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

export default function DepartmentDetails() {
  const departmentId = useParams().id;
  const [department, setDepartment] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const getDepartment = () => {
      api
        .get(`/departments/${departmentId}`)
        .then((response) => {
          setDepartment(response.data);
          setPageTitle(response.data.name);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setDepartment(undefined);
          }
        });
    };

    const getProjects = () => {
      api
        .get(`/departments/${departmentId}/projects`)
        .then((response) => {
          setProjects(response.data);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setDepartment(undefined);
          }
        });
    };

    getDepartment();
    getProjects();
  }, [departmentId]);

  if (department === null || department === undefined) {
    if (department === undefined) {
      return <NotFound />;
    }
    return <Spinner />;
  }
  return (
    <>
      <div className="container-fluid mb-4">
        <Link className="my-bread-crumb" to={`/departments`}>
          <MdOutlineArrowBackIosNew />
          DEPARTMENTS
        </Link>
        <h2>{department.name}</h2>
        <p>{department.description}</p>
      </div>
      <ProjectList projects={projects} />
    </>
  );
}
