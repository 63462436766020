import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../components/Toast';
import api from '../../../api';

export default function ChangePassword() {
  const encoded_token = useParams().token;
  const [processing, setProcessing] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData(e.target);
    const password = formData.get('password');
    const _password = formData.get('_password');
    if (password !== _password) {
      showToast('Password Mismatched!');
      setProcessing(false);
      return;
    }

    api
      .put(`/reset/${encoded_token}`, formData)
      .then((response) => {
        setProcessing(false);
        window.location.href = '/sign-in';
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response?.status === 404) {
          showToast('Invalid Token!');
        } else {
          showToast('Something went wrong!');
        }
      });
  };
  return (
    <>
      <h1>Create New Password</h1>
      <p>Create a new and secure password.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-floating mt-3">
          <input
            type="password"
            className="form-control"
            id="floatingInput"
            placeholder="new password"
            name="password"
            required
          />
          <label htmlFor="floatingInput">New Password</label>
        </div>
        <div className="form-floating mt-3">
          <input
            type="password"
            className="form-control"
            id="floatingInput"
            placeholder="new password"
            name="_password"
            required
          />
          <label htmlFor="floatingInput">Confirm Password</label>
        </div>
        <button className="w-100 btn btn-lg btn-primary" type="submit">
          {processing ? (
            <>
              <span
                className="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>{' '}
              Processing...
            </>
          ) : (
            'Change Password'
          )}
        </button>
      </form>
    </>
  );
}
