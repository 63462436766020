import React, { useState, useEffect } from 'react';
import api from '../../api';
import './styles.css';
import DepartmentList from '../DepartmentList';
import ProjectList from '../ProjectList';

export default function Sidebar() {
  const [departments, setDepartments] = useState([]);
  const [projects, setProjects] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const getDepartments = () => {
      api
        .get('/departments')
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
    const getProjects = () => {
      api
        .get('/projects')
        .then((response) => {
          setProjects(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getProjects();
    getDepartments();
  }, []);

  useEffect(() => {
    setSearchResults(
      projects.filter((project) => {
        if (project.title.toLowerCase().includes(searchParam.toLowerCase())) {
          return true;
        }
        return false;
      })
    );
  }, [searchParam]);

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  return (
    <>
      <div className="col-lg-4 side-bar mb-4">
        <div className="container-fluid">
          <div className="mb-4 text-start">
            <h4>Search Your Project Topic</h4>
            <small className="text-muted">
              All project materials on this website are well researched by
              professionals with high level of professionalism, or pick from a
              list of departments below.
            </small>
            <input
              type="text"
              className="form-control mt-3"
              placeholder="Search Projects"
              value={searchParam}
              onChange={handleSearch}
            />
          </div>
          {searchParam.length === 0 ? (
            <DepartmentList departments={departments} />
          ) : (
            <ProjectList
              projects={searchParam === '' ? projects : searchResults}
              countsPerPage={9999}
              fromASearch={searchParam.length !== 0}
              paginated={false}
            />
          )}
        </div>
      </div>
    </>
  );
}
