import React from 'react';
import { Link } from 'react-router-dom';

export default function Help() {
  return (
    <>
      <div className="container-fluid bg-primary text-white text-center p-5">
        <h3 className="text-white text-center">
          Not The Topic You Are Looking For?
        </h3>
        <p>
          <small>
            We pride ourselves on maintaining a transparent and reliable
            platform that connects you with talented writers who are committed
            to delivering exceptional results. Your satisfaction is our
            priority, and we strive to make your project experience smooth and
            successful.
          </small>
        </p>
        <Link to="/hire-a-writer" className="btn btn-lg btn-danger">
          Hire A Writer
        </Link>
      </div>
    </>
  );
}
