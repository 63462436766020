import React from 'react';
import { Link } from 'react-router-dom';

export default function TermsOfUse() {
  return (
    <>
      <div className="container-fluid text-start">
        <h1>Terms and Conditions</h1>
        <p>
          Please read these Terms of Use ("Terms", "Terms of Use") carefully
          before using{' '}
          <Link to="https://www.iprojectedu.com">iprojectedu.com</Link> website
          (the "Service") operated by SBCOM Technology ("us", "we", or "our").
        </p>

        <p>
          Your access to and use of the Service is conditioned on your
          acceptance of and compliance with these Terms. These Terms apply to
          all visitors, users and others who access or use the Service.
        </p>

        <p>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </p>

        <div className="my-5">
          <h5>TERMS OF USE</h5>
          <p>
            All project materials on this website are NOT for submission to any
            higher institution for academic awards. They are solely meant to
            guide final year students on the various processes and methodologies
            of writing a research project, iprojectedu.com does NOT encourage
            students to use our materials WORD TO WORD, as we do not encourage
            plagiarism.
          </p>

          <p>
            For academic purpose, it is illegal for any student to download
            research projects on this website site and submit to any higher
            institution for academic awards. Students can stand the risk of
            being expelled if caught by project supervisors. To get a risk-free
            customized research project click here for custom writing service!
          </p>
          <p>
            All e-books, study software, academic journals and study packs are
            not original properties of iprojectedu.com. These materials are
            totally free on this website and we do not claim ownership or what
            so ever.
          </p>

          <p>
            Our custom writing services are never duplicates of already existing
            projects on this site. We write from scratch and provide our clients
            with original documents concerning their project topic. After our
            clients accept their work, it becomes their original property and
            deleted from our systems immediately.
          </p>

          <p>
            Clients can always contact our Help Desk at all times. Every
            business must be channeled through iprojectedu.com, as we will not
            bear any loss or liability if you deal with an impostor claiming to
            work with iprojectedu.com
          </p>
        </div>

        <div className="my-5">
          <h5>LINKS TO OTHER WEB SITES</h5>
          <p>
            Our Service may contain links to third-party web sites or services
            that are not owned or controlled by SBCOM Technology. SBCOM
            Technology has no control over, and assumes no responsibility for,
            the content, privacy policies, or practices of any third party web
            sites or services. You further acknowledge and agree that SBCOM
            Technology shall not be responsible or liable, directly or
            indirectly, for any damage or loss caused or alleged to be caused by
            or in connection with use of or reliance on any such content, goods
            or services available on or through any such web sites or services.
          </p>
        </div>

        <div className="my-5">
          <h5>CHANGES TO TERMS</h5>
          <p>
            We reserve the right, at our sole discretion, to modify or replace
            these Terms at any time. If a revision is material we will try to
            provide at least 30 days' notice prior to any new terms taking
            effect. What constitutes a material change will be determined at our
            sole discretion.
          </p>
        </div>

        <div className="my-5">
          <h5>CONTACT US</h5>
          <p>
            If you have any questions about these Terms, please contact us
            through{' '}
            <Link to="mailto:support@iprojectedu.com">
              support@iprojectedu.com
            </Link>
          </p>
        </div>
      </div>
    </>
  );
}
