import React, { useEffect, useState } from 'react';
import api from '../../api';
import { showToast } from '../../components/Toast';
import Spinner from '../../components/Spinner';
import { BsFillInboxFill } from 'react-icons/bs';
import global from '../../data/global.json';
import { HiOutlineUserCircle } from 'react-icons/hi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { MdDone } from 'react-icons/md';
import { TbRefresh } from 'react-icons/tb';

export default function ManageRequests() {
  const [requests, setRequests] = useState([]);
  useEffect(() => {
    api
      .get('/requests')
      .then((response) => {
        setRequests(response.data.reverse());
      })
      .catch((error) => {
        showToast('Something Went Wrong!');
      });
  }, []);

  const [searchParam, setSearchParam] = useState('');
  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  const [filteredRequests, setFilteredRequests] = useState(requests);
  useEffect(() => {
    setFilteredRequests(
      requests.filter((request) => {
        if (
          request.project_title
            .toLowerCase()
            .includes(searchParam.toLowerCase()) ||
          request.educational_level
            .toLowerCase()
            .includes(searchParam.toLowerCase()) ||
          request.plan.toLowerCase().includes(searchParam.toLowerCase()) ||
          request.user.email
            .toLowerCase()
            .includes(searchParam.toLowerCase()) ||
          request.user.phone
            .toLowerCase()
            .includes(searchParam.toLowerCase()) ||
          request.user.firstname
            .toLowerCase()
            .includes(searchParam.toLowerCase()) ||
          request.user.lastname
            .toLowerCase()
            .includes(searchParam.toLowerCase())
        ) {
          return true;
        }
      })
    );
  }, [requests, searchParam]);
  return (
    <>
      <div className="container-fluid mb-3">
        <h4>Manage Project Requests</h4>
        <p>
          <small className="text-muted">
            All project requests will appear here with contact details including
            requests details.
          </small>
        </p>
        <div>
          <input
            type="text"
            className="form-control"
            placeholder="Search Requests"
            value={searchParam}
            onChange={handleSearch}
          />
        </div>
      </div>

      <RequestList requests={filteredRequests} />
    </>
  );
}

function RequestList({ requests = [] }) {
  if (requests.length === 0) {
    return <Spinner />;
  }

  const requestStatus = {
    Pending: 'warning',
    Completed: 'success',
    Failed: 'danger',
    Fulfilled: 'primary',
  };

  const deleteRequest = (requestId) => {
    api
      .delete(`/requests/${requestId}`)
      .then((response) => {
        showToast('Request Deleted!');
        window.location.reload();
      })
      .catch((error) => {
        showToast('Something went Wrong!');
      });
  };

  const toggleRequest = (requestId) => {
    api
      .put(`/requests/${requestId}`)
      .then((response) => {
        showToast('Request Updated!');
        window.location.reload();
      })
      .catch((error) => {
        showToast('Something went Wrong!');
      });
  };

  const refreshRequest = (requestId) => {
    showToast('Checking Request Status');
    api
      .get(`/requests/${requestId}/verify`)
      .then((response) => {
        showToast('Payment Verified, Refreshing!');
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          showToast('Not paid, yet!');
        } else {
          showToast('Something went wrong!');
        }
      });
  };
  return (
    <>
      <div className="container-fluid mb-5">
        <div className="list-group list-group-flush">
          {requests.map((request) => {
            return (
              <div
                className="list-group-item list-group-item-action d-flex justify-content-between py-3"
                key={request.id}
              >
                <div className="d-flex">
                  <div className="me-2">
                    <BsFillInboxFill
                      className="text-primary"
                      style={{ marginTop: '-3px' }}
                    />
                  </div>
                  <div>
                    <p className="text-start">
                      <span className={`badge bg-info me-2`}>
                        {request.educational_level}
                      </span>
                      <span className={`badge bg-primary me-2`}>
                        {request.plan}
                      </span>
                      <span className={`badge bg-secondary me-2`}>
                        {`Chapters: ${request.chapters}`}
                      </span>
                      <span className={`badge bg-success me-2`}>
                        {`${global.currency.ngn}${Number(
                          request.price
                        ).toLocaleString()}`}
                      </span>
                      <span
                        className={`badge bg-${
                          requestStatus[request.status]
                        } me-2`}
                      >
                        {request.status}
                      </span>
                      <span className={`badge bg-black me-2`}>
                        {new Date(request.created_at).toLocaleDateString(
                          undefined,
                          {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          }
                        )}
                      </span>
                      <br />
                      <span className={`badge bg-secondary me-2`}>
                        {request.user.email}
                      </span>
                      <span className={`badge bg-secondary me-2`}>
                        {request.user.phone}
                      </span>
                      <span className={`badge bg-secondary me-2`}>
                        {`${request.user.firstname} ${request.user.lastname}`}
                      </span>
                      <HiOutlineUserCircle className="me-1" />
                      <br />
                      <br />
                      <small>Project Title: </small>
                      <span style={{ fontWeight: '600' }}>
                        {`${request.project_title}`}
                      </span>
                      <br />
                      <small>Department / Format: </small>
                      <span style={{ fontWeight: '600' }}>
                        {`${request.department_format}`}
                      </span>
                    </p>
                  </div>
                </div>
                <div style={{ maxWidth: '35px' }}>
                  {request.status !== 'Completed' ? (
                    ''
                  ) : (
                    <button
                      className="btn btn-sm btn-success m-1"
                      onClick={() => toggleRequest(request.id)}
                    >
                      <MdDone />
                    </button>
                  )}
                  {request.status === 'Fulfilled' ||
                  request.status === 'Completed' ? (
                    ''
                  ) : (
                    <button
                      className="btn btn-sm btn-danger m-1"
                      onClick={() => deleteRequest(request.id)}
                    >
                      <RiDeleteBin6Line />
                    </button>
                  )}
                  {request.status === 'Pending' ? (
                    <button
                      className="btn btn-sm btn-warning m-1"
                      onClick={() => refreshRequest(request.id)}
                    >
                      <TbRefresh />
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
