import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import NotFound from '../../404';
import Spinner from '../../../components/Spinner';
import api from '../../../api';
import { BiBookAlt } from 'react-icons/bi';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';
import { ImPageBreak } from 'react-icons/im';
import { TbCurrencyNaira } from 'react-icons/tb';
import './styles.css';
import { setPageTitle } from '../../../utils/PageTitle';
import Help from '../../../components/Help';
import { showToast } from '../../../components/Toast';
import { safeHtml } from '../../../utils/sanitizeHtml';

export default function ProjectDetails() {
  const projectId = useParams().id;
  const [project, setProject] = useState(null);
  const [user, setUser] = useState(null);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    const getProject = () => {
      api
        .get(`/projects/${projectId}`)
        .then((response) => {
          setProject(response.data);
          setPageTitle(response.data.title);
        })
        .catch((error) => {
          if (error.response && error.response.status === 404) {
            setProject(undefined);
          }
          console.error(error);
        });
    };
    const getUser = () => {
      api
        .get('/users/@me')
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {
          console.warn('No User found!');
        });
    };

    getProject();
    getUser();
  }, [projectId]);

  const processOrder = (e) => {
    setProcessing(true);
    const data = { project_id: project.id };
    api
      .post('/orders', data)
      .then((response) => {
        setProcessing(false);
        showToast('Order Completed, Loading Payment Page!');
        window.location = response.data.auth_url;
      })
      .catch((error) => {
        setProcessing(false);
        showToast('Something went wrong!');
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData(e.target);
    formData.set('project_id', project.id);

    api
      .post('/orders', formData)
      .then((response) => {
        setProcessing(false);
        showToast('Order Completed, Loading Payment Page!');
        window.location = response.data.auth_url;
      })
      .catch((error) => {
        setProcessing(false);
        showToast('Something went wrong!');
      });
  };

  if (project === null || project === undefined) {
    if (project === undefined) {
      return <NotFound />;
    }
    return <Spinner />;
  }
  return (
    <>
      <div className="container-fluid mb-5">
        <Link
          className="my-bread-crumb"
          to={`/departments/${project.department.id}`}
        >
          <MdOutlineArrowBackIosNew />
          {project.department.name}
        </Link>
        <h2 className="project-title">{project.title}</h2>
        <div className="project-details">
          <p>
            <BiBookAlt />
            {`${project.chapters} Chapters`}
          </p>
          <p>
            <ImPageBreak />
            {`${project.pages} Pages`}
          </p>
          <p>
            <TbCurrencyNaira />
            {`${project.price.toLocaleString()}`}
          </p>
        </div>
        <section
          className="py-4 px-3 px-md-5"
          dangerouslySetInnerHTML={{ __html: safeHtml(project.body) }}
        ></section>

        <div className="my-5">
          <h5
            className="text-center text-primary mb-3"
            style={{
              fontSize: '25px',
              fontWeight: '700',
              textTransform: 'uppercase',
            }}
          >
            <span className="text-danger">Get Complete Project: </span>
            {project.title}
          </h5>

          {user === null ? (
            <form onSubmit={handleSubmit}>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Field cannot be blank"
                  name="firstname"
                  required
                />
                <label htmlFor="floatingInput">Firstname</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Field cannot be blank"
                  name="lastname"
                  required
                />
                <label htmlFor="floatingInput">Lastname</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Field cannot be blank"
                  name="email"
                  required
                />
                <label htmlFor="floatingInput">Email</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Field cannot be blank"
                  name="phone"
                  required
                />
                <label htmlFor="floatingInput">Phone</label>
              </div>

              <button className="btn btn-lg btn-primary w-100" type="submit">
                {processing ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>{' '}
                    Processing...
                  </>
                ) : (
                  'Get Complete Project'
                )}
              </button>
            </form>
          ) : (
            <>
              <p className="text-center text-muted">
                <small>
                  You are logged in as{' '}
                  <span style={{ fontWeight: 700 }}> {user.email} </span>, and
                  your purchase will be bind to the stated email.
                </small>
              </p>
              <button
                className="btn btn-lg btn-primary w-100"
                onClick={processOrder}
              >
                {processing ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>{' '}
                    Processing...
                  </>
                ) : (
                  'Get Complete Project'
                )}
              </button>
            </>
          )}
        </div>
      </div>
      <div className="container-fluid mb-5">
        <Help />
      </div>
    </>
  );
}
