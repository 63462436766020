import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { showToast } from '../../components/Toast';
import api from '../../api';

export default function Verify() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState(0);

  useEffect(() => {
    setLoading(true);
    const verifyPayment = () => {
      showToast('Verifying Transaction!');
      api
        .get(`/verify/${searchParams.get('reference')}`)
        .then((response) => {
          setLoading(false);
          showToast('Payment Verified!');
          setStatus(response.status);
        })
        .catch((error) => {
          setLoading(false);
          setStatus(error.response.status);
          if (error.response.status === 422) {
            showToast('Transaction Failed!');
          } else {
            showToast('Something went wrong!');
          }
        });
    };
    verifyPayment();
  }, []);

  if (loading === true) {
    return <Spinner />;
  }

  if (status === 200) {
    return (
      <>
        <div className="container">
          <div className="container-fluid">
            <h2>Your payment has been confirmed!</h2>
            <p>
              <small className="text-muted">
                Your payment has been confirmed and your order was marked
                completed, we will get back to you with your full project
                project delivered to your email or WhatsApp. If you have any
                further questions, request or enquiries kindly use the chat box
                widget to chat with one of our front desk officer. Thank you.
              </small>
            </p>

            <a href="/" className="btn btn-primary btn-lg me-3">
              Explore More Project Topics
            </a>

            <a href="/profile" className="btn btn-secondary btn-lg me-3">
              My Profile
            </a>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="container">
          <div className="container-fluid">
            <h2 className="text-danger">Transaction Failed!</h2>
            <p>
              <small className="text-muted">
                Sorry we are unable to verify your payment. If you have any
                further questions, request or enquiries kindly use the chat box
                widget to chat with one of our front desk officer. Thank you.
              </small>
            </p>

            <a href="/" className="btn btn-primary btn-lg me-3">
              Explore More Project Topics
            </a>

            <a href="/profile" className="btn btn-secondary btn-lg me-3">
              My Profile
            </a>
          </div>
        </div>
      </>
    );
  }
}
