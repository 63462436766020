import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import NotFound from './pages/404';
import ScrollToTop from './utils/ScrollToTop';
import Sidebar from './components/Sidebar';
import Projects from './pages/Projects';
import Departments from './pages/Departments';
import DepartmentDetails from './pages/Departments/DepartmentDetails';
import ProjectDetails from './pages/Projects/ProjectDetails';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import Logout from './pages/Logout';
import Manage from './pages/Manage';
import { Toast } from './components/Toast';
import Verify from './pages/Verify';
import HireAWriter from './pages/HireAWriter';
import TermsOfUse from './pages/TermsOfUse';
import Reset from './pages/SignIn/Reset';
import ChangePassword from './pages/SignIn/ChangePassword';

function App() {
  return (
    <>
      <Toast />
      <div className="body">
        <div className="wrapper">
          <ScrollToTop />
          <Navbar />
          <main>
            <div className="container mt-5">
              <div className="row">
                <div className="col-lg-8">
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/projects" element={<Projects />} />
                    <Route path="/hire-a-writer" element={<HireAWriter />} />
                    <Route path="/sign-in" element={<SignIn />} />
                    <Route path="/sign-in/reset" element={<Reset />} />
                    <Route
                      path="/sign-in/reset/:token"
                      element={<ChangePassword />}
                    />
                    <Route path="/sign-up" element={<SignUp />} />
                    <Route path="/logout" element={<Logout />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="/admin/manage" element={<Manage />} />
                    <Route path="/verify" element={<Verify />} />
                    <Route
                      path="/terms-and-conditions"
                      element={<TermsOfUse />}
                    />
                    <Route path="/departments" element={<Departments />} />
                    <Route
                      path="/departments/:id"
                      element={<DepartmentDetails />}
                    />
                    <Route path="/projects/:id" element={<ProjectDetails />} />
                  </Routes>
                </div>
                <Sidebar />
              </div>
            </div>
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default App;
