import React, { useEffect, useState } from 'react';
import { BiSolidPurchaseTag } from 'react-icons/bi';
import { TbRefresh } from 'react-icons/tb';
import { HiOutlineUserCircle } from 'react-icons/hi';
import api from '../../api';
import { showToast } from '../../components/Toast';
import Spinner from '../../components/Spinner';
import global from '../../data/global.json';
import { MdDone } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';

export default function ManageOrders() {
  const [searchParam, setSearchParam] = useState('');
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filterByStatus, setFilterByStatus] = useState([]);
  const [filterControl, setFilterControls] = useState({
    Completed: false,
    Fulfilled: false,
    Pending: false,
  });

  useEffect(() => {
    const getOrders = () => {
      api
        .get('/orders')
        .then((response) => {
          setOrders(response.data.reverse());
        })
        .catch((error) => {
          showToast('Something Went Wrong!');
        });
    };

    getOrders();
  }, []);

  useEffect(() => {
    setFilterByStatus(
      orders.filter((order) => {
        if (filterControl[order.status]) {
          return true;
        }
        return false;
      })
    );
  }, [orders, filterControl]);

  useEffect(() => {
    setFilteredOrders(
      filterByStatus.filter((order) => {
        if (
          order.project.title.toLowerCase().includes(searchParam.toLowerCase())
        ) {
          return true;
        }
        if (
          order.user.email.toLowerCase().includes(searchParam.toLowerCase())
        ) {
          return true;
        }
        if (
          order.user.phone.toLowerCase().includes(searchParam.toLowerCase())
        ) {
          return true;
        }
        if (
          order.project.department.name
            .toLowerCase()
            .includes(searchParam.toLowerCase())
        ) {
          return true;
        }
        return false;
      })
    );
  }, [searchParam, filterByStatus]);

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  const handleFilterChange = (e) => {
    let newFilter = {};
    Object.entries(filterControl).map(([key, value], index) => {
      newFilter[key] = false;
    });
    setFilterControls({ ...newFilter, [e.target.dataset.filterName]: true });
  };
  return (
    <>
      <div className="container-fluid mb-3">
        <h4>Manage Orders</h4>
        <p>
          <small className="text-muted">
            Search or filter orders by user (name, email), project (title), or
            order status.
          </small>
        </p>
        <div className="d-flex mb-3">
          {Object.entries(filterControl).map(([key, value], index) => {
            return (
              <div className="form-check form-switch d-flex me-3" key={index}>
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  id="flexSwitchCheckDefault"
                  data-filter-name={key}
                  onChange={handleFilterChange}
                  checked={value}
                />
                <small className="text-muted">{key}</small>
              </div>
            );
          })}
        </div>
        <input
          type="text"
          className="form-control"
          placeholder="Search Orders"
          value={searchParam}
          onChange={handleSearch}
        />
      </div>

      <OrderList
        orders={
          searchParam === ''
            ? Object.values(filterControl).reduce((acc, curr) => acc || curr)
              ? filterByStatus
              : orders
            : filteredOrders
        }
      />
    </>
  );
}

function OrderList({ orders = [] }) {
  const orderStatus = {
    Pending: 'warning',
    Completed: 'success',
    Failed: 'danger',
    Fulfilled: 'primary',
  };
  if (orders.length === 0) {
    return <Spinner />;
  }
  const markOrderAsFulfilled = (orderId) => {
    showToast('Marking Order as Fulfilled!');
    api
      .put(`/orders/${orderId}`, { status: 'fulfilled' })
      .then((response) => {
        showToast('Order Fulfilled!');
        window.location.reload();
      })
      .catch((error) => {
        showToast('Something went wrong!');
      });
  };
  const refreshOrderStatus = (orderId) => {
    showToast('Refreshing Order Status!');
    api
      .get(`/orders/${orderId}/verify`)
      .then((response) => {
        showToast('Order was Confirmed!');
        window.location.reload();
      })
      .catch((error) => {
        if (error.response.status === 422) {
          showToast('Not paid, yet!');
        } else {
          showToast('Something went wrong!');
        }
      });
  };

  const deleteOrder = (orderId) => {
    showToast('Deleting Order!');
    api
      .delete(`/orders/${orderId}`)
      .then((repsonse) => {
        showToast('Deletion Success!');
        window.location.reload();
      })
      .catch((error) => {
        showToast('Something went wrong!');
      });
  };

  return (
    <>
      <div className="container-fluid mb-5">
        <div className="list-group list-group-flush">
          {orders.map((order, index) => {
            return (
              <div
                className="list-group-item list-group-item-action d-flex justify-content-between py-3"
                key={index}
              >
                <div className="d-flex">
                  <div className="me-2">
                    <BiSolidPurchaseTag
                      className="text-primary"
                      style={{ marginTop: '-3px' }}
                    />
                  </div>
                  <div className="order-details">
                    <p>{order.project?.title}</p>
                    <p>
                      <span className="badge bg-primary me-2">
                        {global.currency.ngn}
                        {order.project.price.toLocaleString()}
                      </span>

                      <span
                        className={`badge bg-${orderStatus[order.status]} me-2`}
                      >
                        {order.status}
                      </span>
                      <span className={`badge bg-info me-2`}>
                        {order.project.department.name}
                      </span>
                      <span className={`badge bg-black me-2`}>
                        {new Date(order.created_at).toLocaleDateString(
                          undefined,
                          {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                          }
                        )}
                      </span>
                    </p>
                    <div>
                      <p>
                        <span className={`badge bg-secondary me-2`}>
                          {order.user.email}
                        </span>
                        <span className={`badge bg-secondary me-2`}>
                          {order.user.phone}
                        </span>
                        <span className={`badge bg-secondary me-2`}>
                          {`${order.user.firstname} ${order.user.lastname}`}
                        </span>
                        <HiOutlineUserCircle className="me-1" />
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div style={{ maxWidth: '35px' }}>
                    {order.status !== 'Completed' ? (
                      ''
                    ) : (
                      <button
                        className="btn btn-sm btn-success m-1"
                        onClick={() => markOrderAsFulfilled(order.id)}
                      >
                        <MdDone />
                      </button>
                    )}
                    {order.status === 'Fulfilled' ||
                    order.status === 'Completed' ? (
                      ''
                    ) : (
                      <button
                        className="btn btn-sm btn-danger m-1"
                        onClick={() => deleteOrder(order.id)}
                      >
                        <RiDeleteBin6Line />
                      </button>
                    )}
                    {order.status === 'Pending' ? (
                      <button
                        className="btn btn-sm btn-warning m-1"
                        onClick={() => refreshOrderStatus(order.id)}
                      >
                        <TbRefresh />
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
