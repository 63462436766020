import React from 'react';
import './styles.css';
import { Link } from 'react-router-dom';

export default function Footer() {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  return (
    <>
      <footer className="text-center py-5">
        <div className="container px-5">
          <div className="small">
            <div className="mb-2">
              <span>
                iProjectEdu &copy; {currentYear} All Rights Reserved. |
              </span>
              <Link to="/terms-and-conditions"> Terms & Conditions</Link>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
