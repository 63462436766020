import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import api from '../../api';
import { showToast } from '../../components/Toast';
import { setPageTitle } from '../../utils/PageTitle';

export default function SignUp() {
  const [processing, setProcessing] = useState(false);
  setPageTitle('Sign Up');
  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData(e.target);
    if (formData.get('password') === formData.get('c_password')) {
      api
        .post('/users', formData)
        .then((response) => {
          setProcessing(false);
          window.location = '/sign-in';
        })
        .catch((error) => {
          setProcessing(false);
          if (error.response?.status === 409) {
            showToast('User Already Exists, Please Sign In!');
          } else {
            showToast('Something went wrong!');
          }
        });
    } else {
      showToast('Password miss-matched!');
      setProcessing(false);
    }
  };
  return (
    <>
      <div className="container">
        <div className="container-fluid mb-5">
          <main className="form-signin">
            <form onSubmit={handleSubmit}>
              <h1 className="display-6 mb-3 fw-normal">Create an Account</h1>
              <p>
                <small className="text-muted">
                  Get access to all exlusive features we offered. Create an
                  account for personalized experience.
                </small>
              </p>

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="firstname"
                  placeholder="John"
                  name="firstname"
                  required
                />
                <label htmlFor="firstname">Firstname</label>
              </div>

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="lastname"
                  placeholder="DOE"
                  name="lastname"
                  required
                />
                <label htmlFor="lastname">Lastname</label>
              </div>

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="+234 700 0000 00"
                  name="phone"
                  required
                />
                <label htmlFor="phone">Phone</label>
              </div>

              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  name="email"
                  required
                />
                <label htmlFor="floatingInput">Email address</label>
              </div>

              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="address"
                  placeholder="Address"
                  name="address"
                  required
                />
                <label htmlFor="address">Address</label>
              </div>

              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="floatingPassword"
                  placeholder="Password"
                  name="password"
                  required
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>

              <div className="form-floating">
                <input
                  type="password"
                  className="form-control"
                  id="c_password"
                  placeholder="Confirm Password"
                  name="c_password"
                  required
                />
                <label htmlFor="c_password">Confirm Password</label>
              </div>

              <button className="w-100 btn btn-lg btn-primary" type="submit">
                {processing ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Creating Account...
                  </>
                ) : (
                  'Create Account'
                )}
              </button>
            </form>
            <div className="text-center text-muted mt-3">
              Already have an Account? <Link to="/sign-in">Sign In!</Link>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
